import React from "react";
// import { useSelector } from "react-redux";
// import { selectUsers } from "../userSlice";
import styles from "./UserList.module.scss";

const UserListSum: React.FC = () => {
  // const users = useSelector(selectUsers);
  return (
    <div className={styles.root}>
      {/* {users.map((user) => (
        <UserItemSum key={user.name} user={user} />
      ))} */}
    </div>
  );
};

export default UserListSum;
