import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import Modal from "@material-ui/core/Modal";
import { AppDispatch } from "../../../app/store";
import styles from "./PresentationDetail.module.scss";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import { handelModalOpen, selectIsModalOpen } from "../presentationSlice";
import {
  fetchAsks,
  countUp,
  selectAsk,
  selectSelectedAsk,
} from "../../ask/askSlice";

import PresentationEntry from "../../presentation/presentationFrom/PresentaionEntry";

type PropTypes = {
  edit?: boolean;
  guest?: boolean;
  ask?: {
    id: string;
    name: string;
  };
};

const PresentationDetail: React.FC<PropTypes> = ({ ask }) => {
  const isModalOpen = useSelector(selectIsModalOpen);
  const selectedAsk = useSelector(selectSelectedAsk);
  const dispatch: AppDispatch = useDispatch();

  const handleOpen = async () => {
    dispatch(selectAsk(ask));
    dispatch(handelModalOpen(true));
  };

  const handleClose = () => {
    dispatch(handelModalOpen(false));
  };

  const handelCount = async (id: string, count: number) => {
    if (count == null) {
      count = 0;
    }
    const sendData = { id, count: count + 1 };
    await countUp(sendData);
    dispatch(fetchAsks());
    dispatch(handelModalOpen(false));
  };

  return (
    <div className={styles.presentation_wrapper}>
      <button onClick={() => handleOpen()} className={styles.edit_button}>
        『{ask?.name}』さんへプレゼンする!
      </button>
      <Modal open={isModalOpen} onClose={handleClose} className={styles.modal}>
        <div className={styles.modal_content}>
          <div className={styles.modal_title}>
            <div className={styles.target_user}>
              <div className={styles.user}>
                {selectedAsk.gender === "男性" ? (
                  <div className={styles.icon_male}>
                    <AccountCircleIcon
                      titleAccess={selectedAsk.name}
                      style={{ fontSize: 100 }}
                    />
                    <span className={styles.target_username}>
                      {selectedAsk.name}さん
                    </span>
                  </div>
                ) : (
                  <div className={styles.icon_female}>
                    <AccountCircleIcon
                      titleAccess={selectedAsk.name}
                      style={{ fontSize: 100 }}
                    />
                    <span className={styles.target_username}>
                      {selectedAsk.name}さん
                    </span>
                  </div>
                )}

                {selectedAsk.theme === "恋愛" && (
                  <span className={styles.love}>{selectedAsk.theme}</span>
                )}
                {selectedAsk.theme === "仕事" && (
                  <span className={styles.work}>{selectedAsk.theme}</span>
                )}
                {selectedAsk.theme === "人生" && (
                  <span className={styles.life}>{selectedAsk.theme}</span>
                )}
                <span className={styles.target}>
                  {selectedAsk.targetAge === "年齢問わず"
                    ? ""
                    : selectedAsk.targetAge}
                  {selectedAsk.targetGender === "男女問わず"
                    ? ""
                    : selectedAsk.targetGender}
                  求む
                </span>
                <div className={styles.like_count}>
                  <button
                    onClick={() =>
                      handelCount(selectedAsk.id, selectedAsk.count)
                    }
                    className={styles.like_button}
                  >
                    <ThumbUpAltIcon className={styles.icon} />
                    <span className={styles.like_count}>
                      {selectedAsk.count}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.target_text}>
              『{selectedAsk.name}』
              <span style={{ color: "gray" }}>さんは</span>『
              {selectedAsk.ideal_match}』
              <span style={{ color: "gray" }}>を求めています。</span>
              <br></br>『{selectedAsk.theme_important}』
              <span style={{ color: "gray" }}>を大切にし、</span>『
              {selectedAsk.theme_give}』
              <span style={{ color: "gray" }}>ができて、</span>『
              {selectedAsk.theme_dislike}』
              <span style={{ color: "gray" }}>はNGです。</span>
              <span className={styles.datetime}>
                {format(selectedAsk.createdAt, "MM/dd HH:mm")}
              </span>
            </div>
            {selectedAsk.name}
            さんのメールにあなたのプレゼン内容をお送りします。<br></br>
            OKの場合は、あなたのメールアドレスにプレゼン日程調整のお知らせが届きます！
          </div>
          <PresentationEntry key={ask?.id} ask={ask} />
        </div>
      </Modal>
    </div>
  );
};

export default PresentationDetail;
