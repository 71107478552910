import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Header from "./components/header/Header";
import PresentationList from "./features/presentation/presentationList/PresentationList";
import { fetchPresentations } from "./features/presentation/presentationSlice";
import { AppDispatch } from "./app/store";
import styles from "./App.module.scss";
import { auth } from "./firebase";

const App: React.FC<RouteComponentProps> = (props) => {
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // !user && props.history.push("user-auth-demo");
      !user && props.history.push("/user-login");
    });
    const getData = () => {
      dispatch(fetchPresentations());
    };
    getData();
  }, [props.history]);

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <Header history={props.history} />
        <PresentationList />
      </div>
    </div>
  );
};

export default App;
