import React from "react";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "./UserAsk.module.scss";

import AskForm from "../components/AskForm";
import { useForm, FormProvider } from "react-hook-form";
import { createUser } from "../features/user/userSlice";
import { auth } from "../firebase";

interface Inputs {
  isFirst: boolean;
  name: string;
  email: string;
  gender: string;
  birthYear: string;
  theme: string;
  targetAge: string;
  targetGender: string;
  ideal_match: string;
  theme_important: string;
  theme_give: string;
  theme_dislike: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  gender: yup.string().required(),
  birthYear: yup.number().min(1960).max(2005).required(),
  theme: yup.string().required(),
  targetAge: yup.string().required(),
  targetGender: yup.string().required(),
  ideal_match: yup.string().required(),
  theme_important: yup.string().required(),
  theme_give: yup.string().required(),
  theme_dislike: yup.string().required(),
});

const UserAsk: React.FC = () => {
  const history = useHistory();
  const methods = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const handleCreate = async (data: Inputs) => {
    await createUser(
      data.isFirst,
      data.name,
      data.email,
      data.gender,
      data.birthYear,
      data.theme,
      data.targetAge,
      data.targetGender,
      data.ideal_match,
      data.theme_important,
      data.theme_give,
      data.theme_dislike
    );
    console.log("data", data);
    try {
      // props.history.push("/");
    } catch (err) {
      alert("ログインができませんでした、再度お試しください");
    }
  };

  // プレゼン求む登録処理
  const CreateAsk = async (data: Inputs) => {
    handleCreate(data);
    alert(
      "投稿完了" +
        data.name +
        data.email +
        data.gender +
        data.birthYear +
        data.theme +
        data.targetAge
    );
    history.push("/presen");
  };

  return (
    <div className={styles.root}>
      <div className={styles.title_text}>
        {auth.currentUser?.email === "guest@umiiiku.com" ? (
          <div className={styles.text_field}>ゲストログイン中</div>
        ) : (
          <div className={styles.text_field}>
            {auth.currentUser?.email}でログイン中
          </div>
        )}
      </div>
      <div className={styles.wrapper}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(CreateAsk)}
            className={styles.form}
          >
            <AskForm />
            <div className={styles.button_wrapper}>
              <button type="submit" className={styles.submit_button}>
                プレゼンを依頼する
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default UserAsk;
