import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "./MemberEdit.module.scss";

import MemberForm from "./MemberForm";
import { useForm, FormProvider } from "react-hook-form";
import { editMember } from "../../../features/user/userSlice";
interface Inputs {
  profile: string;
}
import { useParams } from "react-router-dom";

const schema = yup.object().shape({
  profile: yup.string().required(),
});

const MemberEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const methods = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const handleEdit = async (data: Inputs) => {
    const sendData = { id: id, profile: data.profile };
    await editMember(sendData);
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleEdit)}
            className={styles.form}
          >
            <MemberForm />
            <div className={styles.button_wrapper}>
              <button type="submit" className={styles.submit_button}>
                プロフィールを編集する
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default MemberEdit;
