import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import firebase from "firebase/app";
import { RootState } from "../../app/store";
import { db } from "../../firebase";
import { auth } from "../../firebase";

// stateの型
interface UserState {
  // userが何人いるのか管理
  idCount: number;
  // storeに保存するuser一覧
  users: {
    id: string;
    email: string;
    name: string;
    gender: string;
    birthYear: number;
    // theme: string;
    // targetAge: string;
    // targetGender: string;
    // ideal_match: string;
    // theme_dislike: string;
    // theme_give: string;
    // theme_important: string;
    createdAt: Date;
    // count: number;
  }[];
  // userのカウントを編集する際にどのuserが選択されているか
  selectedUser: {
    id: string;
    email: string;
    name: string;
    gender: string;
    birthYear: number;
    // theme: string;
    // targetAge: string;
    // targetGender: string;
    // ideal_match: string;
    // theme_dislike: string;
    // theme_give: string;
    // theme_important: string;
    createdAt: Date;
    // count: number;
  };
}

// stateの初期値
const initialState: UserState = {
  idCount: 1,
  users: [],
  selectedUser: {
    id: "",
    email: "",
    name: "",
    gender: "",
    birthYear: 0,
    // theme: "",
    // targetAge: "",
    // targetGender: "",
    // ideal_match: "",
    // theme_important: "",
    // theme_give: "",
    // theme_dislike: "",
    createdAt: new Date(),
    // count: 0,
  },
};

/* ====================
       ユーザー新規作成
======================*/

export const createUser = async (
  isFirst: boolean,
  name: string,
  email: string,
  gender: string,
  birthYear: string,
  theme: string,
  targetAge: string,
  targetGender: string,
  ideal_match: string,
  theme_important: string,
  theme_give: string,
  theme_dislike: string
): Promise<void> => {
  try {
    const dateTime = firebase.firestore.Timestamp.fromDate(new Date());
    await db
      .collection("users")
      .where("email", "==", email)
      .get()
      .then((emailExist) => {
        console.log("isFirst:" + isFirst);
        console.log("email:" + email);
        console.log(emailExist.size);
        console.log(emailExist.empty);

        if ((emailExist.empty && email !== "guest@umiiiku.com") || !isFirst) {
          if (isFirst) {
            db.collection("users").add({
              name: name,
              email: email,
              gender: gender,
              birthYear: birthYear,
              createdAt: dateTime,
            });
          }

          db.collection("asks").add({
            name: name,
            email: email,
            gender: gender,
            birthYear: birthYear,
            theme: theme,
            targetAge: targetAge,
            targetGender: targetGender,
            ideal_match: ideal_match,
            theme_important: theme_important,
            theme_give: theme_give,
            theme_dislike: theme_dislike,
            createdAt: dateTime,
          });
          // } catch (err) {
          //   console.log("Error writing document: ", err);
          // }
        } else {
          alert(
            email + "はすでに登録されています。ログインしてからご利用ください"
          );
        }
      });
  } catch (err) {
    console.log("Error writing document: ", err);
  }
};

/* ====================
       ユーザー情報編集
======================*/

export const editMember = async (submitData: {
  id: string;
  profile: string;
}): Promise<void> => {
  const { id, profile } = submitData;
  const dateTime = firebase.firestore.Timestamp.fromDate(new Date());
  try {
    await db
      .collection("users")
      .doc(id)
      .set({ profile, dateTime }, { merge: true });
  } catch (err) {
    console.log("Error updating document:", err);
  }
};

/* ====================
       ユーザー取得
======================*/
export const fetchUsers = createAsyncThunk("user/getAllUsers", async () => {
  const res2 = await db.collection("users").orderBy("createdAt", "desc").get();
  const allUsers = res2.docs.map((doc) => ({
    id: doc.id,
    email: doc.data().email !== auth.currentUser?.email ? "" : doc.data().email,
    name: doc.data().name,
    gender: doc.data().gender,
    birthYear: doc.data().birthYear,
    // theme: doc.data().theme,
    // targetAge: doc.data().targetAge,
    // targetGender: doc.data().targetGender,
    // ideal_match: doc.data().ideal_match,
    // theme_dislike: doc.data().theme_dislike,
    // theme_give: doc.data().theme_give,
    // theme_important: doc.data().theme_important,
    createdAt: doc.data().createdAt.toDate(),
    // count: doc.data().count,
  }));

  const userNumber = allUsers.length;
  const passData = { allUsers, userNumber };
  return passData;
});

/* ====================
       emailログインユーザー取得
======================*/

export const fetchUser = createAsyncThunk("user/getLoginUser", async () => {
  const email = auth.currentUser?.email;
  const res1 = await db.collection("users").where("email", "==", email).get();
  const user = res1.docs.map((doc) => ({
    email: doc.data().email !== auth.currentUser?.email ? "" : doc.data().email,
    name: doc.data().name,
    gender: doc.data().gender,
    birthYear: doc.data().birthYear,
    createdAt: doc.data().createdAt.toDate(),
  }));
  const passData = { user };
  return passData;
});

/* ====================
       カウントアップ
======================*/

export const countUp = async (submitData: {
  id: string;
  count: number;
}): Promise<void> => {
  const { id, count } = submitData;
  try {
    await db.collection("users").doc(id).set({ count }, { merge: true });
  } catch (err) {
    console.log("Error updating count:", err);
  }
};

export const userSlice = createSlice({
  // このsliceの名前。actionTypeを生成するときにprefixとなる。
  name: "user",
  // このsliceで用いるinitialStateの値
  initialState,
  // reducersの中身を記述
  reducers: {
    // どのユーザーを選択しているか管理
    selectUser: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload.allUsers;
      state.idCount = action.payload.userNumber;
    });
  },
});

export const { selectUser } = userSlice.actions;

// コンポーネント側からuseSlectorを用いてselectTaskを指定することで
// stateの値をコンポーネントに渡すことが可能
export const selectUsers = (state: RootState): UserState["users"] =>
  state.user.users;

export const selectSelectedUser = (
  state: RootState
): UserState["selectedUser"] => state.user.selectedUser;

export default userSlice.reducer;
