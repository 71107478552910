import React from "react";
import styles from "./UserItem.module.scss";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { format } from "date-fns";
import { getYear } from "date-fns";
import { NavLink } from "react-router-dom";

interface PropTypes {
  user: {
    id: string;
    email: string;
    name: string;
    gender: string;
    birthYear: number;
    createdAt: Date;
  };
}

const UserItem: React.FC<PropTypes> = ({ user }) => {
  const date = new Date();
  return (
    <div className={styles.wrapper}>
      <div className={styles.user}>
        {user.gender === "男性" && (
          <div className={styles.icon_male}>
            <AccountCircleIcon fontSize="large" />
          </div>
        )}
        {user.gender === "女性" && (
          <div className={styles.icon_female}>
            <AccountCircleIcon fontSize="large" />
          </div>
        )}
        <div className={styles.title_text}>
          『<NavLink to={`/member/${user.id}`}>{user.name}</NavLink>』さん(
          {getYear(date) - user.birthYear})
          <span className={styles.datetime}>
            {format(user.createdAt, "MM/dd HH:mm")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserItem;
