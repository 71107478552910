import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import styles from "./UserHome.module.scss";
import { useForm } from "react-hook-form";
// import firebase from "firebase";
import { auth } from "../../firebase";

interface AuthDataTypes {
  email: string;
}

const emailLogin = window.localStorage.getItem("emailForSignIn");

const UserHome: React.FC<RouteComponentProps> = (props) => {
  const { handleSubmit } = useForm<AuthDataTypes>();

  // メールログイン処理
  const MailSignIn = () => {
    if (auth.currentUser !== null) {
      props.history.push("/");
    }
    if (
      auth.isSignInWithEmailLink(location.href) &&
      emailLogin != null &&
      auth.currentUser === null
    ) {
      auth
        .signInWithEmailLink(emailLogin, location.href)
        .then((result) => {
          // ログイン完了
          // ローカルに保存したメールアドレスを削除
          window.localStorage.removeItem("EmailForLogin");
          //   dispatch(fetchUsers());
          console.log("result", result);
          props.history.push("/top");
        })
        .catch((error) => {
          // ローカルに保存したメールアドレスを削除
          window.localStorage.removeItem("EmailForLogin");
          //   dispatch(fetchUsers());
          console.log("error", error);
          alert("再度メールログインをお試しください");
          props.history.push("/user-login");
        });
    } else {
      alert("再度メールログインをお試しください");
      props.history.push("/user-login");
    }
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // console.log("user id: " + auth.currentUser?.uid);
      user && props.history.push("/top");
    });
  }, [props.history]);

  return (
    <div className={styles.root}>
      {auth.currentUser === null && (
        <form onSubmit={handleSubmit(MailSignIn)} noValidate>
          <div className={styles.button}>
            <button type="submit" className={styles.login_button}>
              {emailLogin}でログイン
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default UserHome;
