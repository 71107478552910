import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import styles from "./MemberForm.module.scss";

const MemberForm: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <div className={styles.text_field}>
        <Controller
          name="profile"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="プロフィール"
              variant="outlined"
              error={!!errors.profile}
              helperText={errors.profile && "プロフィールは必須項目です"}
              className={styles.row}
            />
          )}
        />
      </div>
    </div>
  );
};

export default MemberForm;
