import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import styles from "./PresentationForm.module.scss";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { auth } from "../../../firebase";
import { db } from "../../../firebase";

import getDate from "date-fns/getDate";
import getDay from "date-fns/getDay";
import addDays from "date-fns/addDays";
import getMonth from "date-fns/getMonth";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import endOfWeek from "date-fns/endOfWeek";
import eachWeekOfInterval from "date-fns/eachWeekOfInterval";

type PropTypes = {
  edit?: boolean;
  guest?: boolean;
  ask?: {
    id: string;
    name: string;
  };
};

const getCalendarArray = (date: Date) => {
  const sundays = eachWeekOfInterval({
    start: addDays(date, 7),
    end: endOfWeek(addDays(date, 14)),
  });
  return sundays.map((sunday) =>
    eachDayOfInterval({ start: sunday, end: endOfWeek(sunday) })
  );
};
const targetDate = new Date();
const PresentationDates = getCalendarArray(targetDate);

const PresentationForm: React.FC<PropTypes> = ({ ask }) => {
  const [loginName, setloginName] = useState("");
  const [loginGender, setloginGender] = useState("");
  const [loginBirthYear, setloginBirthYear] = useState("");
  const [isUser, setisUser] = useState("");
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  // ログインユーザー情報取得
  const getEmailLoginUser = async () => {
    try {
      const email = auth.currentUser?.email;

      const res1 = await db
        .collection("users")
        .where("email", "==", email)
        .get();
      const loginUser = res1.docs.map((doc) => ({
        email:
          doc.data().email !== auth.currentUser?.email ? "" : doc.data().email,
        name: doc.data().name,
        gender: doc.data().gender,
        birthYear: doc.data().birthYear,
        createdAt: doc.data().createdAt.toDate(),
      }));

      // alert(
      //   email +
      //     "のメールアドレスで会員登録されているかチェックしました 結果：" +
      //     loginUser[0].name +
      //     loginUser[0].gender +
      //     loginUser[0].birthYear
      // );
      setloginName(loginUser[0].name);
      setloginGender(loginUser[0].gender);
      setloginBirthYear(loginUser[0].birthYear);
    } catch (err) {
      setisUser("false");
      // alert("会員情報の登録がありません");
    }
  };

  return (
    <div>
      {auth.currentUser?.email === "guest@umiiiku.com" ? (
        <div className={styles.text_field}>"ゲストログイン中"</div>
      ) : (
        <button
          onClick={() => getEmailLoginUser()}
          className={styles.style_button}
        >
          会員情報を取得（２回名以降の方のみ）
        </button>
      )}
      {auth.currentUser?.email === "guest@umiiiku.com" ? (
        <div className={styles.text_field}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                type="email"
                label="メールアドレス"
                variant="outlined"
                error={!!errors.email}
                helperText={
                  errors.email && "メールアドレスを正しく入力してください"
                }
                className={styles.row}
              />
            )}
          />
        </div>
      ) : (
        <div className={styles.text_field}>
          登録メールアドレス： {auth.currentUser?.email}
          {setValue("email", auth.currentUser?.email)}
        </div>
      )}
      {!loginName && isUser !== "false" ? (
        <div className={styles.text_field}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                label="ニックネーム"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name && "名前は必須項目です"}
                className={styles.row}
                onChange={() => getEmailLoginUser()}
              />
            )}
          />
          {setValue("isFirst", true)}
        </div>
      ) : (
        ""
      )}
      {!loginName && isUser === "false" ? (
        <div className={styles.text_field}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                label="ニックネーム"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name && "名前は必須項目です"}
                className={styles.row}
              />
            )}
          />
          {setValue("isFirst", true)}
        </div>
      ) : (
        <div className={styles.text_field}>
          ニックネーム： {loginName}
          {setValue("name", loginName)}
          {setValue("isFirst", false)}
        </div>
      )}
      {loginGender === "男性" && (
        <div className={styles.text_field}>
          性別： {loginGender}
          {setValue("gender", loginGender)}
        </div>
      )}
      {loginGender === "女性" && (
        <div className={styles.text_field}>
          性別： {loginGender}
          {setValue("gender", loginGender)}
        </div>
      )}
      {loginGender !== "男性" && loginGender !== "女性" && (
        <div className={styles.text_field}>
          <Controller
            name="gender"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <RadioGroup row aria-label="gender" name="gender1">
                <FormControlLabel
                  {...field}
                  value="男性"
                  control={<Radio />}
                  label="男性"
                />
                <FormControlLabel
                  {...field}
                  value="女性"
                  control={<Radio />}
                  label="女性"
                />
              </RadioGroup>
            )}
          />

          <FormHelperText className={styles.alert}>
            {errors.gender && "性別は必須項目です"}
          </FormHelperText>
        </div>
      )}
      {!loginBirthYear ? (
        <div className={styles.text_field}>
          <Controller
            name="birthYear"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="誕生年（西暦４桁数字）"
                variant="outlined"
                error={!!errors.birthYear}
                helperText={
                  errors.birthYear &&
                  "西暦が正しくありません、生まれた年は半角数字４桁で入力してください(1960〜2005)"
                }
                className={styles.row}
              />
            )}
          />
        </div>
      ) : (
        <div className={styles.text_field}>
          誕生年： {loginBirthYear}
          {setValue("birthYear", loginBirthYear)}
        </div>
      )}

      {/* <section>
              <label htmlFor="title">プレゼンタイトル</label>
              <div className={styles.text_error}>
                <ErrorMessage name="title" />
              </div>
              <Field
                id={ask?.id + "_title"}
                name="title"
                placeholder="例）企画の説明、自己アピールetc..."
                className={styles.text_field}
              />
            </section> */}

      <div className={styles.text_field}>
        <Controller
          name="title"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="プレゼンタイトル"
              variant="outlined"
              error={!!errors.title}
              helperText={errors.title && "名前は必須項目です"}
              className={styles.row}
            />
          )}
        />
      </div>

      {/* <section>
                <label htmlFor="email">メールアドレス</label>
                <div className={styles.text_error}>
                  <ErrorMessage name="email" />
                </div>
                <Field
                  id={ask?.id + "_email"}
                  name="email"
                  placeholder="あなたのメールアドレス"
                  type="email"
                  className={styles.text_field}
                />
              </section> */}
      <div>
        <Controller
          name="askDate"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <RadioGroup row aria-label="askDate" name="askDate1">
              <table className={styles.date_table}>
                <tbody>
                  {PresentationDates.map((weekRow, rowNum) => (
                    <tr key={rowNum}>
                      {weekRow.map((date) => (
                        <tr key={getDay(date)}>
                          {getDay(date) === 0 && (
                            <div className={styles.date_field}>
                              <FormControlLabel
                                {...field}
                                value={
                                  (getMonth(date) < 9 ? "0" : "") +
                                  (getMonth(date) + 1) +
                                  "/" +
                                  (getDate(date) < 10 ? "0" : "") +
                                  getDate(date) +
                                  "(日)11:00"
                                }
                                control={<Radio />}
                                label={
                                  (getMonth(date) < 9 ? "0" : "") +
                                  (getMonth(date) + 1) +
                                  "/" +
                                  (getDate(date) < 10 ? "0" : "") +
                                  getDate(date) +
                                  "(日)11:00"
                                }
                              />
                            </div>
                          )}

                          {getDay(date) === 3 && (
                            <div className={styles.date_field}>
                              <FormControlLabel
                                {...field}
                                value={
                                  (getMonth(date) < 9 ? "0" : "") +
                                  (getMonth(date) + 1) +
                                  "/" +
                                  (getDate(date) < 10 ? "0" : "") +
                                  getDate(date) +
                                  "(水)20:00"
                                }
                                control={<Radio />}
                                label={
                                  (getMonth(date) < 9 ? "0" : "") +
                                  (getMonth(date) + 1) +
                                  "/" +
                                  (getDate(date) < 10 ? "0" : "") +
                                  getDate(date) +
                                  "(水)20:00"
                                }
                              />
                            </div>
                          )}
                          {getDay(date) === 6 && (
                            <div className={styles.date_field}>
                              <FormControlLabel
                                {...field}
                                value={
                                  (getMonth(date) < 9 ? "0" : "") +
                                  (getMonth(date) + 1) +
                                  "/" +
                                  (getDate(date) < 10 ? "0" : "") +
                                  getDate(date) +
                                  "(土)15:00"
                                }
                                control={<Radio />}
                                label={
                                  (getMonth(date) < 9 ? "0" : "") +
                                  (getMonth(date) + 1) +
                                  "/" +
                                  (getDate(date) < 10 ? "0" : "") +
                                  getDate(date) +
                                  "(土)15:00"
                                }
                              />
                            </div>
                          )}
                        </tr>
                      ))}
                    </tr>
                  ))}
                </tbody>
                <FormHelperText className={styles.alert}>
                  {errors.askDate && "プレゼン日程は必須項目です"}
                </FormHelperText>
              </table>
            </RadioGroup>
          )}
        />

        {setValue("Uid", ask?.id)}
      </div>
    </div>
  );
};

export default PresentationForm;
