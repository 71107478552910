import React from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { auth } from "../../firebase";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://umiiiku.com/">
        UMiiiKU
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  button: {
    backgroundColor: "#33c9dc",
    "&:hover": {
      backgroundColor: "#00bcd4",
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UserAuth: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles(props);
  const { handleSubmit } = useForm();

  // ゲストログイン処理
  const handleGuestSignIn = () => {
    try {
      auth.signInWithEmailAndPassword(
        "guest@umiiiku.com",
        "kaiinnninarimasyou2021"
      );
      props.history.push("/");
    } catch (err) {
      alert("ログインができませんでした、再度お試しください");
    }
  };

  // useEffect(() => {
  //   auth.onAuthStateChanged((user) => {
  //     // auth.currentUser?.email === "aaa@aaa.com"
  //     //   ? user && props.history.push("/user-list")
  //     //   : user && props.history.push("/");
  //     if (auth.currentUser?.email === "aaa@aaa.com")
  //       user && props.history.push("/user-ask");
  //     else user && props.history.push("/");
  //   });
  // }, [props.history]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container justify="center">
          <img
            src="https://static.wixstatic.com/media/07ba41_157113149d0c4f4298e5788d7479b49f~mv2.png"
            width="300"
            alt="UMiiiKU"
          ></img>
        </Grid>
        <Typography component="h1" variant="h5" align="center">
          『人生とは出会いである』
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          〜マッチング理論〜
        </Typography>
        <form onSubmit={handleSubmit(handleGuestSignIn)}>
          <Box m={1}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
            >
              みんなの出会い願望【ゲストログイン】
            </Button>
          </Box>
        </form>
      </div>
      <Grid container justify="center">
        <a href="/user-auth">
          <Box m={3}>プレゼンルーム会員登録</Box>
        </a>
      </Grid>
      <Grid container justify="center">
        <a href="https://www.umiiiku.com/">
          <Box m={3}>UMiiiKU会員サイトへ</Box>
        </a>
      </Grid>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default UserAuth;
