import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { db } from "../../firebase";
import { auth } from "../../firebase";

// stateの型
interface AskState {
  // 求むが何件あるのか管理
  idCount: number;
  // storeに保存する求む一覧
  asks: {
    id: string;
    email: string;
    name: string;
    gender: string;
    birthYear: number;
    theme: string;
    targetAge: string;
    targetGender: string;
    ideal_match: string;
    theme_dislike: string;
    theme_give: string;
    theme_important: string;
    createdAt: Date;
    count: number;
  }[];
  // 求むを編集する際にどの求むが選択されているか
  selectedAsk: {
    id: string;
    email: string;
    name: string;
    gender: string;
    birthYear: number;
    theme: string;
    targetAge: string;
    targetGender: string;
    ideal_match: string;
    theme_dislike: string;
    theme_give: string;
    theme_important: string;
    createdAt: Date;
    count: number;
  };
}

// stateの初期値
const initialState: AskState = {
  idCount: 1,
  asks: [],
  selectedAsk: {
    id: "",
    email: "",
    name: "",
    gender: "",
    birthYear: 0,
    theme: "",
    targetAge: "",
    targetGender: "",
    ideal_match: "",
    theme_important: "",
    theme_give: "",
    theme_dislike: "",
    createdAt: new Date(),
    count: 0,
  },
};

/* ====================
       求む取得
======================*/
export const fetchAsks = createAsyncThunk("ask/getAllAsks", async () => {
  const res2 = await db.collection("asks").orderBy("createdAt", "desc").get();
  const allAsks = res2.docs.map((doc) => ({
    id: doc.id,
    email: doc.data().email !== auth.currentUser?.email ? "" : doc.data().email,
    name: doc.data().name,
    gender: doc.data().gender,
    birthYear: doc.data().birthYear,
    theme: doc.data().theme,
    targetAge: doc.data().targetAge,
    targetGender: doc.data().targetGender,
    ideal_match: doc.data().ideal_match,
    theme_dislike: doc.data().theme_dislike,
    theme_give: doc.data().theme_give,
    theme_important: doc.data().theme_important,
    createdAt: doc.data().createdAt.toDate(),
    count: doc.data().count,
  }));

  const askNumber = allAsks.length;
  const passData = { allAsks, askNumber };
  return passData;
});

/* ====================
       emailログインユーザー取得
======================*/

export const fetchUser = createAsyncThunk("user/getLoginUser", async () => {
  const email = auth.currentUser?.email;
  const res1 = await db
    .collection("users")
    .where("email", "==", email)
    .orderBy("createdAt", "desc")
    .get();
  const user = res1.docs.map((doc) => ({
    email: doc.data().email !== auth.currentUser?.email ? "" : doc.data().email,
    name: doc.data().name,
    gender: doc.data().gender,
    birthYear: doc.data().birthYear,
    createdAt: doc.data().createdAt.toDate(),
  }));
  const passData = { user };
  return passData;
});

/* ====================
       カウントアップ
======================*/

export const countUp = async (submitData: {
  id: string;
  count: number;
}): Promise<void> => {
  const { id, count } = submitData;
  try {
    await db.collection("asks").doc(id).set({ count }, { merge: true });
  } catch (err) {
    console.log("Error updating count:", err);
  }
};

export const askSlice = createSlice({
  // このsliceの名前。actionTypeを生成するときにprefixとなる。
  name: "ask",
  // このsliceで用いるinitialStateの値
  initialState,
  // reducersの中身を記述
  reducers: {
    // どのユーザーを選択しているか管理
    selectAsk: (state, action) => {
      state.selectedAsk = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsks.fulfilled, (state, action) => {
      state.asks = action.payload.allAsks;
      state.idCount = action.payload.askNumber;
    });
  },
});

export const { selectAsk } = askSlice.actions;

// コンポーネント側からuseSlectorを用いてselectTaskを指定することで
// stateの値をコンポーネントに渡すことが可能
export const selectAsks = (state: RootState): AskState["asks"] =>
  state.ask.asks;

export const selectSelectedAsk = (state: RootState): AskState["selectedAsk"] =>
  state.ask.selectedAsk;

export default askSlice.reducer;
