import React from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import styles from "./Header.module.scss";
import * as H from "history";
import { auth } from "../../firebase";
import { Box } from "@material-ui/core";
import { useLocation } from "react-router-dom";

interface PropTypes {
  history: H.History;
}

const Header: React.FC<PropTypes> = ({ history }) => {
  const location = useLocation();

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      history.push("user-login");
    } catch (err) {
      alert(err);
    }
  };
  // 求む
  const askPresen = async () => {
    try {
      history.push("/ask");
    } catch (err) {
      alert("エラー、再度お試しください");
    }
  };
  // する
  const doPresen = async () => {
    try {
      history.push("/presen");
    } catch (err) {
      alert("エラー、再度お試しください");
    }
  };
  // 聴く
  const entryPresen = async () => {
    try {
      history.push("/join");
    } catch (err) {
      alert("エラー、再度お試しください");
    }
  };

  return (
    <div className={styles.root}>
      {/* <AppBar position="static" className={styles.app_bar}>
        <Toolbar className={styles.tool_bar}>
          <Typography variant="subtitle2" className={styles.title}>
            <a href="https://umiiiku.com" className={styles.logo}>
              <img
                src="https://static.wixstatic.com/media/07ba41_157113149d0c4f4298e5788d7479b49f~mv2.png"
                width="50"
                alt="UMiiiKU"
              ></img>
            </a>
            <Box m={1}>
              <a href="/top">EVENT</a>
              {location.pathname === "/ask" && " / プレゼン求む"}
              {location.pathname === "/presen" && " / プレゼンする"}
              {location.pathname === "/join" && " / プレゼン聴く"}
            </Box>
          </Typography>
          <Button onClick={handleSignOut}>
            {auth.currentUser?.email ? "ログアウト" : "ログイン"}
          </Button>
        </Toolbar>{" "}
        <div>
          <button onClick={() => askPresen()} className={styles.button}>
            求む
          </button>
          <button onClick={() => doPresen()} className={styles.button}>
            する
          </button>
          <button onClick={() => entryPresen()} className={styles.button}>
            聴く
          </button>
        </div>
      </AppBar> */}
      <Tabs value={value} onChange={handleChange} aria-label="icon tabs">
        <Tab icon={<SearchIcon />} label="EVENT" />
        <Tab icon={<HomeIcon />} label="HOME" />
        <Tab icon={<AccountCircleIcon />} label="MYPAGE" />
      </Tabs>
    </div>
  );
};
export default Header;
