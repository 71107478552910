import React from "react";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "./PresentationEntry.module.scss";
import PresentationForm from "./PresentationForm";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { createPresentation, handelModalOpen } from "../presentationSlice";
import { selectSelectedAsk } from "../../ask/askSlice";

interface Inputs {
  isFirst: boolean;
  name: string;
  email: string;
  gender: string;
  birthYear: string;
  title: string;
  Uid?: string;
  askDate: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  gender: yup.string().required(),
  birthYear: yup.number().min(1960).max(2005).required(),
  title: yup.string().required(),
  Uid: yup.string().required(),
  askDate: yup.string().required(),
});

type PropTypes = {
  edit?: boolean;
  guest?: boolean;
  ask?: {
    id: string;
    name: string;
  };
};

// interface Values {
//   email: string;
//   title: string;
//   Uid?: string;
//   askDate: string;
//   checked: [];
// }

const PresentationEntry: React.FC<PropTypes> = ({ ask }) => {
  const history = useHistory();
  const methods = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const handleCreate = async (data: Inputs) => {
    await createPresentation(
      data.isFirst,
      data.name,
      data.email,
      data.gender,
      data.birthYear,
      data.title,
      data.Uid,
      data.askDate
    );
    console.log("data", data);
    try {
      // props.history.push("/");
    } catch (err) {
      alert("ログインができませんでした、再度お試しください");
    }
  };

  //   プレゼンする登録処理
  const CreatePresen = async (data: Inputs) => {
    handleCreate(data);
    alert(
      "投稿完了" +
        data.name +
        data.email +
        data.gender +
        data.birthYear +
        data.title +
        data.Uid +
        data.askDate
    );
    history.push("/join");
  };

  const selectedAsk = useSelector(selectSelectedAsk);
  const dispatch: AppDispatch = useDispatch();

  //   const handleCreate = async (data: Values) => {
  //     data.Uid = selectedAsk.id;
  //     if (data.askDate.length < 1) {
  //       alert("日付を選択してください");
  //     } else {
  //       await createPresentation(data.email, data.title, data.Uid, data.askDate);
  //       dispatch(fetchPresentations());
  //       dispatch(handelModalOpen(false));
  //     }
  //   };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(CreatePresen)}
            className={styles.form}
          >
            <PresentationForm key={ask?.id} ask={ask} />
            <div className={styles.button_wrapper}>
              <button type="submit" className={styles.submit_button}>
                {selectedAsk.name}さんへプレゼンの申し込みをする
              </button>
              <button
                type="button"
                onClick={() => dispatch(handelModalOpen(false))}
                className={styles.cancel_button}
              >
                閉じる
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default PresentationEntry;
