import React from "react";
import { useSelector } from "react-redux";
import { selectUsers } from "../userSlice";
import UserItem from "../userItem/UserItem";
import styles from "./UserList.module.scss";

const UserList: React.FC = () => {
  const users = useSelector(selectUsers);
  return (
    <div className={styles.root}>
      <div className={styles.title_text}>
        現在のUMiiiKU会員数：{users.length}人
      </div>
      {users.map((user) => (
        <UserItem key={user.name} user={user} />
      ))}
    </div>
  );
};

export default UserList;
