import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import AppSum from "./AppSum";
import AppAsk from "./AppAsk";
import AppJoin from "./AppJoin";
import AppPresen from "./AppPresen";
import AppMember from "./AppMember";
import AppMypage from "./AppMypage";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Top from "./pages/userAuth/Top";
import UserHome from "./pages/userAuth/UserHome";
import UserLogin from "./pages/userAuth/UserLogin";
import UserAuth from "./pages/userAuth/UserAuth";
import UserAuthDemo from "./pages/userAuth/UserAuthDemo";
import { debugContextDevtool } from "react-context-devtool";

const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/top" component={Top} />
          <Route exact path="/user-login" component={UserLogin} />
          <Route exact path="/user-home" component={UserHome} />
          <Route exact path="/user-auth" component={UserAuth} />
          <Route exact path="/user-auth-demo" component={UserAuthDemo} />
          <Route exact path="/ask" component={AppAsk} />
          <Route exact path="/presen" component={AppPresen} />
          <Route exact path="/join" component={AppJoin} />
          <Route exact path="/user-list" component={AppSum} />
          <Route exact path="/member/:id" component={AppMember} />
          <Route exact path="/mypage/:id" component={AppMypage} />
        </Switch>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  container
);

debugContextDevtool(container, { disable: true });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
