import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import styles from "./AskForm.module.scss";

import AskFormMember from "./AskFromMember";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

const AskForm: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <AskFormMember />
      <div className={styles.text_field}>
        <Controller
          name="theme"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <RadioGroup row aria-label="theme" name="theme1">
              <FormControlLabel
                {...field}
                value="恋愛"
                control={<Radio />}
                label="恋愛"
              />
              <FormControlLabel
                {...field}
                value="仕事"
                control={<Radio />}
                label="仕事"
              />
              <FormControlLabel
                {...field}
                value="人生"
                control={<Radio />}
                label="人生"
              />
            </RadioGroup>
          )}
        />
        <FormHelperText className={styles.alert}>
          {errors.theme && "テーマは必須項目です"}
        </FormHelperText>
      </div>
      <div className={styles.text_field}>
        <Controller
          name="targetAge"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <RadioGroup row aria-label="targetAge" name="targetAge1">
              <FormControlLabel
                {...field}
                value="年齢問わず"
                control={<Radio />}
                label="年齢問わず"
              />
              <FormControlLabel
                {...field}
                value="年上"
                control={<Radio />}
                label="年上"
              />
              <FormControlLabel
                {...field}
                value="年下"
                control={<Radio />}
                label="年下"
              />
              <FormControlLabel
                {...field}
                value="同い年"
                control={<Radio />}
                label="同い年"
              />
            </RadioGroup>
          )}
        />
        <FormHelperText className={styles.alert}>
          {errors.targetAge && "プレゼンして欲しい年齢は必須項目です"}
        </FormHelperText>
      </div>
      <div className={styles.text_field}>
        <Controller
          name="targetGender"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <RadioGroup row aria-label="targetGender" name="targetGender1">
              <FormControlLabel
                {...field}
                value="性別問わず"
                control={<Radio />}
                label="性別問わず"
              />
              <FormControlLabel
                {...field}
                value="男性"
                control={<Radio />}
                label="男性求む"
              />
              <FormControlLabel
                {...field}
                value="女性"
                control={<Radio />}
                label="女性求む"
              />
            </RadioGroup>
          )}
        />
        <FormHelperText className={styles.alert}>
          {errors.targetGender && "プレゼンして欲しい性別は必須項目です"}
        </FormHelperText>
      </div>
      <div className={styles.text_field}>
        <Controller
          name="ideal_match"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="何を求める？"
              variant="outlined"
              error={!!errors.ideal_match}
              helperText={errors.ideal_match && "何を求める？は必須項目です"}
              className={styles.row}
            />
          )}
        />
      </div>
      <div className={styles.text_field}>
        <Controller
          name="theme_important"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="最も大切にすることは？"
              variant="outlined"
              error={!!errors.theme_important}
              helperText={
                errors.theme_important && "最も大切にすることは？は必須項目です"
              }
              className={styles.row}
            />
          )}
        />
      </div>
      <div className={styles.text_field}>
        <Controller
          name="theme_give"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="あなたがプレゼンターに提供できることは？"
              variant="outlined"
              error={!!errors.theme_give}
              helperText={
                errors.theme_give &&
                "あなたがプレゼンターに提供できることは？は必須項目です"
              }
              className={styles.row}
            />
          )}
        />
      </div>
      <div className={styles.text_field}>
        <Controller
          name="theme_dislike"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="最もやってはいけないことは何？"
              variant="outlined"
              error={!!errors.theme_dislike}
              helperText={
                errors.theme_dislike &&
                "最もやってはいけないことは何？は必須項目です"
              }
              className={styles.row}
            />
          )}
        />
      </div>
    </div>
  );
};

export default AskForm;
