import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AskItem.module.scss";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import { format } from "date-fns";
import { fetchAsks, countUp } from "../askSlice";
import TaskForm from "../../task/taskForm/TaskForm";
import TaskItem from "../../task/taskItem/TaskItem";
import { AppDispatch } from "../../../app/store";
import { selectTasks } from "../../task/taskSlice";
import { auth } from "../../../firebase";
import PresentationDetail from "../../presentation/presentationFrom/PresentationDetail";

interface PropTypes {
  ask: {
    id: string;
    email: string;
    name: string;
    gender: string;
    birthYear: number;
    theme: string;
    targetAge: string;
    targetGender: string;
    ideal_match: string;
    theme_dislike: string;
    theme_give: string;
    theme_important: string;
    createdAt: Date;
    count: number;
  };
}

const AskItem: React.FC<PropTypes> = ({ ask }) => {
  const dispatch: AppDispatch = useDispatch();
  const tasks = useSelector(selectTasks);

  const handelCount = async (id: string, count: number) => {
    if (count == null) {
      count = 0;
    }
    const sendData = { id, count: count + 1 };
    await countUp(sendData);
    dispatch(fetchAsks());
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.user}>
        {ask.gender === "男性" && (
          <div className={styles.icon_male}>
            <AccountCircleIcon fontSize="large" />
          </div>
        )}
        {ask.gender === "女性" && (
          <div className={styles.icon_female}>
            <AccountCircleIcon fontSize="large" />
          </div>
        )}
        {ask.theme === "恋愛" && <div className={styles.love}>{ask.theme}</div>}
        {ask.theme === "仕事" && <div className={styles.work}>{ask.theme}</div>}
        {ask.theme === "人生" && <div className={styles.life}>{ask.theme}</div>}

        <span className={styles.target}>
          {ask.targetAge === "年齢問わず" ? "" : ask.targetAge}
          {ask.targetGender === "男女問わず" ? "" : ask.targetGender}求む
        </span>

        <div className={styles.like_count}>
          <button
            onClick={() => handelCount(ask.id, ask.count)}
            className={styles.like_button}
          >
            <ThumbUpAltIcon className={styles.icon} />
            <span className={styles.like_count}>{ask.count}</span>
          </button>
        </div>

        <div className={styles.presentation}>
          {/* <span className={styles.presentation_button}> */}
          <PresentationDetail key={ask.id} ask={ask} />
          {/* </span> */}
        </div>

        {ask.email === auth.currentUser?.email ? (
          <div className={styles.presentation}>日程調整する</div>
        ) : (
          ""
        )}
      </div>

      <div className={styles.title_text}>
        『{ask.name}』<span style={{ color: "gray" }}>さんは</span>『
        {ask.ideal_match}』
        <span style={{ color: "gray" }}>を求めています。</span>
        <br></br>『{ask.theme_important}』
        <span style={{ color: "gray" }}>を大切にし、</span>『{ask.theme_give}』
        <span style={{ color: "gray" }}>ができて、</span>『{ask.theme_dislike}』
        <span style={{ color: "gray" }}>はNGです。</span>
        <span className={styles.datetime}>
          {format(ask.createdAt, "MM/dd HH:mm")}
        </span>
      </div>
      <TaskForm key={ask.id} ask={ask} />
      <div>
        {tasks.flatMap((task) =>
          task.Uid === ask.id ? <TaskItem key={task.id} task={task} /> : []
        )}
      </div>
    </div>
  );
};

export default AskItem;
