import React from "react";
import { useSelector } from "react-redux";
import { selectPresentations } from "../presentationSlice";
import PresentationItem from "../presentationItem/PresentationItem";
import styles from "./PresentationList.module.scss";

const PresentationList: React.FC = () => {
  const presentations = useSelector(selectPresentations);

  return (
    <div className={styles.root}>
      <div className={styles.title_text}>
        現在のプレゼンアピール数：{presentations.length}件
      </div>
      {presentations.map((presentation) => (
        <PresentationItem key={presentation.id} presentation={presentation} />
      ))}
    </div>
  );
};

export default PresentationList;
