import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TaskTitleForm from "./TaskTitleForm";
import { useForm, FormProvider } from "react-hook-form";
import { AppDispatch } from "../../../app/store";
import styles from "./TaskForm.module.scss";
import {
  fetchTasks,
  createTask,
  editTask,
  handelModalOpen,
  selectSelectedTask,
} from "../taskSlice";

type Inputs = {
  taskTitle: string;
  Uid: string;
};

type PropTypes = {
  edit?: boolean;
  ask?: {
    id: string;
  };
};

const schema = yup.object().shape({
  taskTitle: yup.string().required(),
});

const TaskForm: React.FC<PropTypes> = ({ edit, ask }) => {
  const dispatch: AppDispatch = useDispatch();
  const selectedTask = useSelector(selectSelectedTask);
  const methods = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const handleCreate = async (data: Inputs) => {
    await createTask(data.taskTitle, data.Uid);
    methods.reset();
    dispatch(fetchTasks());
  };
  const handleEdit = async (data: Inputs) => {
    const sendData = { ...selectedTask, title: data.taskTitle };
    await editTask(sendData);
    dispatch(handelModalOpen(false));
    dispatch(fetchTasks());
  };
  return (
    <div className={styles.root}>
      <FormProvider {...methods}>
        <form
          onSubmit={
            edit
              ? methods.handleSubmit(handleEdit)
              : methods.handleSubmit(handleCreate)
          }
          className={styles.form}
        >
          <TaskTitleForm key={ask?.id} ask={ask} />
        </form>
      </FormProvider>
    </div>
  );
};

export default TaskForm;
