import React from "react";
import { useSelector } from "react-redux";
import { selectAsks } from "../askSlice";
import AskItem from "../askItem/AskItem";
import styles from "./AskList.module.scss";

const AskList: React.FC = () => {
  const asks = useSelector(selectAsks);
  return (
    <div className={styles.root}>
      <div className={styles.title_text}>
        現在のプレゼンリクエスト数：{asks.length}件
      </div>
      {asks.map((ask) => (
        <AskItem key={ask.id} ask={ask} />
      ))}
    </div>
  );
};

export default AskList;
