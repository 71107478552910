import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { getYear } from "date-fns";
import styles from "./MemberPage.module.scss";
import axios from "axios";
import { auth } from "../../../firebase";
import MemberEdit from "./MemberEdit";

interface jsonType {
  askDate: string;
  title: string;
  email: string;
}

const MemberPage: React.FC = () => {
  const [memberName, setmemberName] = useState("");
  const [memberGender, setmemberGender] = useState("");
  const [memberEmail, setmemberEmail] = useState("");
  const [memberProfile, setemberProfile] = useState("");
  const [memberBirthYear, setmemberBirthYear] = useState(0);
  const [memberPresentation, setmemberPresentation] = useState<jsonType[]>([]);
  // const [memberCreatedAt, setmemberCreatedAt] = useState(new Date());
  const { id } = useParams<{ id: string }>();
  const date = new Date();
  const baseURL =
    "https://asia-northeast1-fir-api-auth-53e95.cloudfunctions.net/";

  useEffect(() => {
    callAPIs();
  }, []);

  async function callAPIs() {
    await ApiGetUser(baseURL + "user/" + id)
      .then(
        (response: {
          data: {
            name: React.SetStateAction<string>;
            gender: React.SetStateAction<string>;
            email: React.SetStateAction<string>;
            profile: React.SetStateAction<string>;
            birthYear: React.SetStateAction<number>;
          };
        }) => {
          setmemberName(response.data.name);
          setmemberGender(response.data.gender);
          setmemberEmail(response.data.email);
          setemberProfile(response.data.profile);
          setmemberBirthYear(response.data.birthYear);
          ApiGetPresentation(baseURL + "presentations/" + response.data.email)
            .then((response) => {
              setmemberPresentation(response.data);
            })
            .catch((error) => {
              console.log("通信失敗");
              console.log(error.status);
              // 失敗したときは空のjsonを返す
            });
        }
      )
      .catch((error: { status: never }) => {
        console.log("通信失敗");
        console.log(error.status);
        // 失敗したときは空のjsonを返す
      });
    console.log("done!");
  }

  // axiosでjson取得
  const ApiGetUser = async (url: string) => {
    try {
      // 指定URLにGET
      const response = await axios.get(url);
      // (↑のawaitがついていると関数が実行完了するまで↓を動作しない仕組み)
      // 動作が完了して、リターンしてきたjsonを返す
      return response;
    } catch (error) {
      // 途中でエラーが出たら強制でエラーをスロー
      throw new Error();
    }
  };

  // axiosでjson取得
  const ApiGetPresentation = async (url: string) => {
    try {
      // 指定URLにGET
      const response = await axios.get<jsonType[]>(url);
      // (↑のawaitがついていると関数が実行完了するまで↓を動作しない仕組み)
      // 動作が完了して、リターンしてきたjsonを返す
      return response;
    } catch (error) {
      // 途中でエラーが出たら強制でエラーをスロー
      throw new Error();
    }
  };

  return (
    <div className={styles.wrapper}>
      {auth.currentUser?.email === memberEmail ? (
        <div className={styles.text_field}>
          "会員情報を編集"
          <MemberEdit />
        </div>
      ) : (
        <div className={styles.text_field}>会員情報を閲覧中</div>
      )}
      メンバーの詳細情報 パスパラメーター：{id}
      メンバー名：{memberName}({memberBirthYear}) ：{memberGender}
      プロフィール：{memberProfile}
      メール：{memberEmail}
      <div className={styles.user}>
        {memberGender === "男性" && (
          <div className={styles.icon_male}>
            <AccountCircleIcon fontSize="large" />
          </div>
        )}
        {memberGender === "女性" && (
          <div className={styles.icon_female}>
            <AccountCircleIcon fontSize="large" />
          </div>
        )}
        <div className={styles.title_text}>
          『{memberName}』さん(
          {getYear(date) - memberBirthYear})
          <span className={styles.datetime}>
            {/* {format(memberCreatedAt, "MM/dd HH:mm")} */}
          </span>
        </div>
        現在のプレゼンアピール数：{memberPresentation.length}件
        {memberPresentation.map((memberPresentation, index) => (
          <div className={styles.title_text} key={index}>
            TITLE:{memberPresentation.title}
            email:{memberPresentation.email}
            {memberPresentation.askDate}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemberPage;
