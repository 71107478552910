import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import taskReducer from "../features/task/taskSlice";
import userReducer from "../features/user/userSlice";
import askReducer from "../features/ask/askSlice";
import presentationReducer from "../features/presentation/presentationSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    task: taskReducer,
    user: userReducer,
    ask: askReducer,
    presentation: presentationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = typeof store.dispatch;
