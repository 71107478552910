import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import firebase from "firebase/app";
import { RootState } from "../../app/store";
import { db } from "../../firebase";
import { auth } from "../../firebase";

// stateの型
interface TaskState {
  // taskが何個あるのか管理
  idCount: number;
  // storeに保存するtask一覧
  tasks: {
    id: string;
    Uid: string;
    title: string;
    completed: boolean;
    email: string;
    dateTime: Date;
  }[];
  // taskのtitleを編集する際にどのtaskが選択されているか
  selectedTask: {
    id: string;
    Uid: string;
    title: string;
    completed: boolean;
    email: string;
    dateTime: Date;
  };
  // Modalを開くか閉じるかのフラグ
  isModalOpen: boolean;
}

// stateの初期値
const initialState: TaskState = {
  idCount: 1,
  tasks: [],
  selectedTask: {
    id: "",
    Uid: "",
    title: "",
    completed: false,
    email: "",
    dateTime: new Date(),
  },
  isModalOpen: false,
};

/* ====================
       タスク取得
======================*/
export const fetchTasks = createAsyncThunk("task/getAllTasks", async () => {
  const res = await db.collection("tasks").orderBy("dateTime", "desc").get();

  const allTasks = res.docs.map((doc) => ({
    id: doc.id,
    Uid: doc.data().Uid,
    title: doc.data().title,
    completed: doc.data().completed,
    email: doc.data().email,
    dateTime: doc.data().dateTime.toDate(),
  }));

  const taskNumber = allTasks.length;
  const passData = { allTasks, taskNumber };
  return passData;
});

/* ====================
       タスク新規作成
======================*/

export const createTask = async (title: string, Uid: string): Promise<void> => {
  try {
    const dateTime = firebase.firestore.Timestamp.fromDate(new Date());
    const email = auth.currentUser?.email;
    await db.collection("tasks").add({
      title: title,
      Uid: Uid,
      completed: false,
      email: email,
      dateTime: dateTime,
    });
  } catch (err) {
    console.log("Error writing document: ", err);
  }
};

/* ====================
       タスク編集
======================*/

export const editTask = async (submitData: {
  id: string;
  Uid: string;
  title: string;
  completed: boolean;
}): Promise<void> => {
  const { id, Uid, title, completed } = submitData;
  const dateTime = firebase.firestore.Timestamp.fromDate(new Date());
  try {
    await db
      .collection("tasks")
      .doc(id)
      .set({ title, Uid, completed, dateTime }, { merge: true });
  } catch (err) {
    console.log("Error updating document:", err);
  }
};

/* ====================
       タスク削除
======================*/

export const deleteTask = async (id: string): Promise<void> => {
  try {
    await db.collection("tasks").doc(id).delete();
  } catch (err) {
    console.log("Error removing document:", err);
  }
};

export const taskSlice = createSlice({
  // このsliceの名前。actionTypeを生成するときにprefixとなる。
  name: "task",
  // このsliceで用いるinitialStateの値
  initialState,
  // reducersの中身を記述
  reducers: {
    // どのタスクを洗濯しているか管理
    selectTask: (state, action) => {
      state.selectedTask = action.payload;
    },
    // もーだるを開くか閉じるかのフラグ管理
    handelModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTasks.fulfilled, (state, action) => {
      state.tasks = action.payload.allTasks;
      state.idCount = action.payload.taskNumber;
    });
  },
});

export const { selectTask, handelModalOpen } = taskSlice.actions;

// コンポーネント側からuseSlectorを用いてselectTaskを指定することで
// stateの値をコンポーネントに渡すことが可能
export const selectTasks = (state: RootState): TaskState["tasks"] =>
  state.task.tasks;

export const selectIsModalOpen = (state: RootState): TaskState["isModalOpen"] =>
  state.task.isModalOpen;

export const selectSelectedTask = (
  state: RootState
): TaskState["selectedTask"] => state.task.selectedTask;

export default taskSlice.reducer;
