import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import firebase from "firebase/app";
import { RootState } from "../../app/store";
import { db } from "../../firebase";
import { init } from "emailjs-com";
import emailjs from "emailjs-com";

// stateの型
interface PresentationState {
  // presentationが何件あるのか管理
  idCount: number;
  // storeに保存するpresentation一覧
  presentations: {
    id: string;
    email: string;
    title: string;
    Uid: string;
    askDate: string;
    createdAt: Date;
  }[];
  // presentationのカウントを編集する際にどのpresentationが選択されているか
  selectedPresentation: {
    id: string;
    email: string;
    title: string;
    Uid: string;
    askDate: string;
    createdAt: Date;
  };
  // Modalを開くか閉じるかのフラグ
  isModalOpen: boolean;
}

// stateの初期値
const initialState: PresentationState = {
  idCount: 1,
  presentations: [],
  selectedPresentation: {
    id: "",
    email: "",
    title: "",
    Uid: "",
    askDate: "",
    createdAt: new Date(),
  },
  isModalOpen: false,
};

/* ====================
       プレセン取得
======================*/
export const fetchPresentations = createAsyncThunk(
  "presentation/getAllPresentations",
  async () => {
    const res = await db
      .collection("presentations")
      .orderBy("askDate", "desc")
      .get();

    const allPresentations = res.docs.map((doc) => ({
      id: doc.id,
      email: doc.data().email,
      title: doc.data().title,
      Uid: doc.data().Uid,
      askDate: doc.data().askDate,
      createdAt: doc.data().createdAt.toDate(),
    }));

    const presentationNumber = allPresentations.length;
    const passData = { allPresentations, presentationNumber };
    return passData;
  }
);

/* ====================
       ターゲットユーザー取得
======================*/

export const fetchUser = async (Uid: string): Promise<void> => {
  try {
    const puserRef = db.collection("users").doc(Uid);
    const userDoc = await puserRef.get();
    const target_userEmali = userDoc.get("email");
    console.log("target_userEmali: ", target_userEmali);
    return target_userEmali;
  } catch (err) {
    console.log("Error removing document:", err);
  }
};

/* ====================
       プレゼン新規作成
======================*/

export const createPresentation = async (
  isFirst: boolean,
  name: string,
  email: string,
  gender: string,
  birthYear: string,
  title: string,
  Uid?: string,
  askDate?: string

  // email: string,
  // title: string,
  // Uid?: string,
  // askDate?: string
): Promise<void> => {
  try {
    const userRef = db.collection("asks").doc(Uid);
    const userDoc = await userRef.get();
    const target_userEmail = userDoc.get("email");
    const target_userNmae = userDoc.get("name");
    if (
      confirm(
        target_userNmae + "さんへプレゼン申請をおこないます。よろしいですか？"
      )
    ) {
      const dateTime = firebase.firestore.Timestamp.fromDate(new Date());

      await db.collection("presentations").add({
        name: name,
        email: email,
        title: title,
        Uid: Uid,
        createdAt: dateTime,
        askDate: askDate,
      });

      //メール送信

      // emailjsのUser_IDを使って初期化
      init(process.env.REACT_APP_EMAILJS_USER_ID);

      // 環境変数からService_IDとTemplate_IDを取得する。
      const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
      const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

      // emailjsのテンプレートに渡すパラメータを宣言
      const templateParams = {
        from_name: email,
        title: title,
        askDates: askDate,
        target_userEmail: target_userEmail,
        target_userNmae: target_userNmae,
      };

      // ServiceId,Template_ID,テンプレートに渡すパラメータを引数にemailjsを呼び出し
      emailjs
        .send(emailjsServiceId, emailjsTemplateId, templateParams)
        .then(() => {
          // do something
          console.log("メール送信完了: ", email, title);
          console.log("target_userEmail: ", target_userEmail);
        });
    } else {
      console.log("no send email document: ", email, title);
    }
  } catch (err) {
    console.log("Error writing document: ", err);
  }
};

/* ====================
       プレゼン編集
======================*/

export const editPresentation = async (submitData: {
  id: string;
  title: string;
}): Promise<void> => {
  const { id, title } = submitData;
  const createdAt = firebase.firestore.Timestamp.fromDate(new Date());
  try {
    await db
      .collection("presentations")
      .doc(id)
      .set({ title, createdAt }, { merge: true });
  } catch (err) {
    console.log("Error updating document:", err);
  }
};

/* ====================
       プレゼン削除
======================*/

export const deletePresentation = async (id: string): Promise<void> => {
  try {
    await db.collection("presentations").doc(id).delete();
  } catch (err) {
    console.log("Error removing document:", err);
  }
};

export const presentationSlice = createSlice({
  // このsliceの名前。actionTypeを生成するときにprefixとなる。
  name: "presentation",
  // このsliceで用いるinitialStateの値
  initialState,
  // reducersの中身を記述
  reducers: {
    // どのプレセンテーションを選択しているか管理
    selectPresentation: (state, action) => {
      state.selectedPresentation = action.payload;
    },
    // もーだるを開くか閉じるかのフラグ管理
    handelModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPresentations.fulfilled, (state, action) => {
      state.presentations = action.payload.allPresentations;
      state.idCount = action.payload.presentationNumber;
    });
  },
});

export const { selectPresentation, handelModalOpen } =
  presentationSlice.actions;

// コンポーネント側からuseSlectorを用いてselectTaskを指定することで
// stateの値をコンポーネントに渡すことが可能
export const selectPresentations = (
  state: RootState
): PresentationState["presentations"] => state.presentation.presentations;

export const selectIsModalOpen = (
  state: RootState
): PresentationState["isModalOpen"] => state.presentation.isModalOpen;

export const selectSelectedPresentation = (
  state: RootState
): PresentationState["selectedPresentation"] =>
  state.presentation.selectedPresentation;

export default presentationSlice.reducer;
