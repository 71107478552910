import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import Header from "./components/header/Header";
import UserAsk from "./pages/UserAsk";
import styles from "./App.module.scss";
import { auth } from "./firebase";

const AppAsk: React.FC<RouteComponentProps> = (props) => {
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // !user && props.history.push("user-auth-demo");
      !user && props.history.push("/user-login");
    });
  }, [props.history]);

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <Header history={props.history} />
        <UserAsk />
      </div>
    </div>
  );
};

export default AppAsk;
