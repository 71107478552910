import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useForm } from "react-hook-form";

import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

import TextField from "@material-ui/core/TextField";
import { auth } from "../../firebase";
import firebase from "firebase/app";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://umiiiku.com/">
        UMiiiKU
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(2, 0, 2),
    backgroundColor: "#33c9dc",
    "&:hover": {
      backgroundColor: "#00bcd4",
    },
  },
}));

interface AuthDataTypes {
  email: string;
}
const emailLogin = window.localStorage.getItem("emailForSignIn");
const REDIRECT_URL = "https://match.umiiiku.com/user-home";
const UserLogin: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthDataTypes>();

  // メールログイン送信
  const handleMailSend = async (data: AuthDataTypes) => {
    const { email } = data;

    const actionCodeSettings = {
      // Firebase Consoleで予め許可リストに登録したリダイレクトURLを指定することで継続URLとして使える（とはいえ今回はサインインページが決まっているのでこのリンクはactionページでは使わない。）
      url: REDIRECT_URL,
      // 今は必ず true.
      handleCodeInApp: true,
    };
    auth
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(function () {
        window.localStorage.setItem("emailForSignIn", email);
        alert(
          email + "にメールを送信しました、メール内リンクをクリックしてください"
        );
      })
      .catch(function (error) {
        // Some error occurred, you can inspect the code: error.code
        alert(error.message);
      });
  };

  const signInWithGoogle = () => {
    // Googleプロバイダオブジェクトのインスタンスを作成
    const provider = new firebase.auth.GoogleAuthProvider();
    // ポップアップウィンドウでログインを行う場合はsignInWithPopupを呼び出す
    auth
      .signInWithPopup(provider)
      .then((user) => {
        alert(user.user?.displayName + "さんでログインしました");
        props.history.push("/top");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  // ゲストログイン処理
  const handleGuestSignIn = () => {
    auth
      .signInWithEmailAndPassword("guest@umiiiku.com", "kaiinnninarimasyou2021")
      .then(() => {
        alert("ゲストでログインしました");
        props.history.push("/top");
      })
      .catch((error) => {
        alert("ログインができませんでした、再度お試しください" + error.message);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <a href="/top">
          <img
            src="https://static.wixstatic.com/media/07ba41_157113149d0c4f4298e5788d7479b49f~mv2.png"
            width="300"
            alt="UMiiiKU"
          ></img>
        </a>
        <Typography component="h1" variant="h5">
          UMiiiKUログイン
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          〜人生とは出会いである〜
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => signInWithGoogle()}
        >
          Googleアカウントで参加
        </Button>
      </div>
      <Box m={1}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => handleGuestSignIn()}
        >
          会員登録せずにゲストとして参加
        </Button>
      </Box>
      <br></br>
      <form onSubmit={handleSubmit(handleMailSend)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              defaultValue={emailLogin}
              label="Email Address"
              autoComplete="email"
              autoFocus
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              {...register("email", {
                required: true,
                pattern:
                  /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
              })}
            />
            {errors.email?.type === "pattern" &&
              "メールアドレスを正しく入力してください"}
          </Grid>
        </Grid>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
        >
          あなたのメールで参加
        </Button>
      </form>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default UserLogin;
