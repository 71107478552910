import React from "react";
import EventNoteIcon from "@material-ui/icons/EventNote";
import styles from "./PresentationItem.module.scss";

interface PropTypes {
  presentation: { id: string; Uid: string; title: string; askDate: string };
}

const PresentationItem: React.FC<PropTypes> = ({ presentation }) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <EventNoteIcon />
        <div className={styles.title_text}>{presentation.askDate}</div>
        <div className={styles.title_text}>{presentation.title}</div>
      </div>
    </div>
  );
};

export default PresentationItem;
