import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import { AppDispatch } from "../../../app/store";
import styles from "./TaskTitleForm.module.scss";
import { handelModalOpen, selectSelectedTask } from "../taskSlice";

type PropTypes = {
  edit?: boolean;
  ask?: {
    id: string;
  };
};

const TaskTitleForm: React.FC<PropTypes> = ({ edit, ask }) => {
  const dispatch: AppDispatch = useDispatch();
  const selectedTask = useSelector(selectSelectedTask);

  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <div className={styles.text_field}>
        <Controller
          name="taskTitle"
          control={control}
          defaultValue={selectedTask.title}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="例）自己紹介、〇〇について、etc..."
              variant="outlined"
              error={!!errors.taskTitle}
              helperText={errors.taskTitle && "アジェンダを記入してください"}
              className={styles.row}
            />
          )}
        />
      </div>
      {edit ? (
        <div className={styles.button_wrapper}>
          <button type="submit" className={styles.submit_button}>
            アジェンダを変更する
          </button>
          <button
            type="button"
            onClick={() => dispatch(handelModalOpen(false))}
            className={styles.cancel_button}
          >
            閉じる
          </button>
        </div>
      ) : (
        <div className={styles.button_wrapper}>
          <button
            type="submit"
            className={styles.submit_button}
            onClick={() => {
              setValue("Uid", ask?.id);
            }}
          >
            アジェンダを作成する
          </button>
        </div>
      )}
    </div>
  );
};

export default TaskTitleForm;
